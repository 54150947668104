import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BuydownProvider } from '../src/scenario/BuydownContext';
import { CookieSetting } from "cookiesetting-component";

let host = window.location.host.toLowerCase();
const hostname = window.location.hostname.toLowerCase();
let pathname = window.location.pathname.toLowerCase();

if (pathname === "/listings") {
  pathname = pathname + "/";
}
if (pathname === "/listings/") {
  let listingSearchURL;
  if (hostname === "localhost") {
    listingSearchURL = "http://" + host + pathname + "search";
  } else {
    listingSearchURL = "https://" + host + pathname + "search";
  }
  window.location.href = listingSearchURL;
}
const root = document.getElementById("root");
render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME} future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <BuydownProvider>
        <App />
      </BuydownProvider>
      <CookieSetting 
          privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
          configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
          analytics={process.env.REACT_APP_ANALYTICS_PATH}
        />
    </BrowserRouter>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
